<template>
  <page-wrap>
    <div class="container">
      <h1 class="page-header">contact me</h1>
    </div>
    <div class="container">
      <h2 class="page-subtitle">my address</h2>
      <div>Tomáš Glabazňa</div>
      <div>Chvalnov 2</div>
      <div>768 05 Chvalnov-Lísky</div>
      <div>Czech Republic, Europe</div>
    </div>
    <div class="container">
      <h2 class="page-subtitle">my phone</h2>
      <a href="tel:+420731516974">+420 731 516974</a>
    </div>
    <div class="container">
      <h2 class="page-subtitle">my email</h2>
      <a href="mailto:tomas@glabazna.cz">tomas@glabazna.cz</a>
    </div>
  </page-wrap>
</template>
<script>
export default {
  name: 'Contacts',
  props: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {
  }
}
</script>
<style>
</style>